:root {
  font-family: Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: var(--main-color);
  /* background-color: #242424; */
  /* --main-color: #646cff; */
  background-color: var(--brand-color);
  /* --primary-color: #032924; */
  --main-color: #04203bff;

  --brand-color: #c1e7fdff;
  --secondary-color: #40897fff;
  --hover-color: #053c5c;
  --white-color: #fff;
  --black-color: #000;
  --error-color: rgba(179, 63, 64, 0.6);
  --box-shadow: 0 0 10px 10px rgba(0, 0, 0, .2);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

/* #root {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  width: 100%;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
} */

#root {
  width: 100%;
  /* max-width: 1200px; */
  margin: auto;
  overflow-x: hidden;
}

body {
  margin: 0;
  display: flex;
  height: 100%;
  overflow-x: hidden;
}

button,
.main--button {
  border-radius: 12px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  border-color: var(--main-color);
  cursor: pointer;
  text-decoration: none;
  color: var(--main-color);
  transition: border-color 0.5s, background-color 0.5s, color 0.25s;
}

button,
.main--button {
  width: 150px;
}

button:hover,
.main--button:hover {
  border-color: var(--hover-color);
  background-color: var(--hover-color);
  color: var(--white-color);
}

button:focus,
button:focus-visible,
.main--button:focus,
.main--button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

.active-button {
  background-color: var(--secondary-color);
  color: var(--white-color);
  border-color: var(--secondary-color);
}

.card--button {
  border-radius: 12px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  border-color: var(--main-color);
  cursor: pointer;
  text-decoration: none;
  transition: border-color 0.25s;
  color: var(--white-color);
  background-color: var(--main-color);
  box-shadow: var(--box-shadow);
  max-width: 400px;
  text-align: center;
}

.card--button:hover {
  border-color: var(--secondary-color);
}

ul {
  list-style: none;
  padding: 0;
}