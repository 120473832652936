h1 {
    font-size: 2rem;
}

.create-trip {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    align-items: center;
}

.create-trip--header {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    padding: 1rem 0;
}

.create-trip--header-title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 1rem;
}

.create-trip--header-img {
    position: relative;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
}

.create-trip--header-img img,
.placeholder-img {
    height: 250px;
    width: 100%;
    border-radius: 12px;
    aspect-ratio: 16/9;
    object-fit: cover;
}

.placeholder-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--main-color);
    font-size: 1.5rem;
}

.placeholder-img span {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.create-trip--header-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.create-trip--placeToGo {
    font-size: xx-large;
    margin: 0;
}

.create-trip--travelDays {
    font-size: 2.5vw;
    margin: 0;
}

.create-trip--header-buttons .main--button,
.create-trip .main--button {
    padding: 0.75rem 1.5rem;
    border-radius: 12px;
    border: none;
    background-color: var(--main-color);
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.create-trip--header-buttons .main--button:hover,
.create-trip .main--button:hover {
    background-color: var(--hover-color);
}


/* FORM */
.create-trip--form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 600px;
    padding: 1rem;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;

}

.input-container input,
.input-container textarea {

    background-image: linear-gradient(#20aee3, #20aee3), linear-gradient(#bfbfbf, #bfbfbf);
    border: 0 none;
    border-radius: 0;
    box-shadow: none;
    float: none;
    background-color: transparent;
    background-position: center bottom, center calc(100% - 1px);
    background-repeat: no-repeat;
    background-size: 0 2px, 100% 1px;
    padding: 0;
    transition: background-size 0s ease-out 0s;
    color: var(--hover-color);
    min-height: 35px;
    display: initial;
    width: 100%;
    outline: none;
    font-size: 15px;
}

.input-container input:focus,
.input-container textarea:focus {
    background-size: 100% 2px, 100% 1px;
    outline: none;
    transition-duration: 0.3s;
    color: #525252;
}

/* Itinerary */
.create-trip--itinerary {
    margin-top: 2rem;
    text-align: left;
}

.create-trip--itinerary hr {
    border: none;
    width: 600px;
    height: 1px;
    max-width: 600px;
    margin: 20px 0;
    /* background: linear-gradient(to right, transparent, var(--main-color), transparent); */
    background-color: var(--main-color)
}

.create-trip--itinerary .day-form {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    max-width: 350px;
    gap: 1rem;
}

@media (min-width: 667px) {
    /* .create-trip--form {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2rem;
        margin: 0 auto;
        border-radius: 12px;
        max-width: 80%;
        width: 100%;
        align-items: center;
    }

    .create-trip--header-buttons {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    } */
}

@media (max-width: 480px) {
    .tooltip {
        display: none;
    }
}