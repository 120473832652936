.home {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 3rem;
}

.home img {
    width: 100%;
    max-width: 300px;
    height: auto;
    display: block;
    margin: 0 auto;
}

.home-maininfo {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    text-align: start;
    align-items: center;
}

.home-leftinfo {
    max-width: 600px;
}

.home-leftinfo span {
    color: var(--hover-color);
}

.home-rightinfo {
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 100%;
}

.home-section {
    display: flex;
    flex-direction: row;
    max-width: 600px;
    align-items: center;
    gap: 1rem;
}

.home-section h2 {
    color: var(--hover-color);
}

.home-section p {
    color: var(--hover-color);
}

.home-section img {
    width: 100px;
    height: 100px;
}

.separator {
    max-width: 600px;
    border: 0;
    height: 1px;
    background: var(--hover-color);
    margin: 20px 0;
}


@media (max-width: 480px) {}

@media (max-width: 900px) {

    .home-maininfo {
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

}