.tooltip {
    position: relative;
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: middle;
}

.tooltip-icon {
    cursor: pointer;
    font-size: 1.25rem;
}

.tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0.5rem;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 105%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-text::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -5px;
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}