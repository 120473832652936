.old-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 100%;
  margin-top: 5rem;
  margin-bottom: 5rem;
  gap: 1rem;
}

.layout {
  display: flex;
  width: 100%;
}

.leftSide {
  width: 250px;
  background-color: #121212;
  background-color: var(--main-color);
  position: fixed;
  height: 100%;
  box-shadow: 1px 0 3px rgba(255, 255, 255, 0.2);
  text-align: center;
  transition: all 0.3s ease;
  padding-top: 1rem;
}

.leftSide img {
  max-width: 150px;
  padding: 1rem;
}

.mainContent {
  margin-left: 250px;
  width: calc(100% - 250px);
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  /* background-color: #1E1E1E; */
  background-color: var(--brand-color);
}

.header {
  grid-column: 1 / 3;
  text-align: center;
  padding: 1rem;
  /* background-color: #121212; */
  background-color: var(--hover-color);
  position: sticky;
  top: 0;
  z-index: 1;
  color: #fff;
  height: 2rem;
  display: none;
}

.middleSide {
  padding: 2rem;
  overflow-y: auto;
  grid-column: 1 / 3;
  overflow: hidden;
  scrollbar-width: none;
}

/* .rightSide {
  grid-column: 2 / 3;
  padding: 2rem;
  overflow-y: auto;
} */

@media (max-width: 900px) {
  .leftSide {
    width: 80px;
  }

  .leftSide img {
    max-width: 50px;
    padding: 1rem;
  }

  .mainContent {
    margin-left: 80px;
    width: 100%;
  }

  .middleSide {
    grid-column: 1 / 3;
    padding: 1rem;
  }

  .rightSide {
    display: none;
  }
}

@media (max-width: 667px) {
  .middleSide {
    grid-column: 1 / 3;
    padding: 1rem;

  }

  .rightSide {
    display: none;
  }
}


@media (max-width: 480px) {
  .leftSide {
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }

  .leftSide img {
    max-width: 40px;
  }

  .layout-logo {
    display: none;
  }

  .mainContent {
    margin: 0;
    width: 100%;
  }

  .middleSide {
    padding-bottom: 65px;
  }
}