.private-profile {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    padding: 1rem;
    max-width: 750px;
    margin: 0 auto;
}

.private-profile--section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.private-profile--section h1 {
    margin: 0;
}

.private-profile--header {
    display: flex;
    flex-direction: column;
    border: 1px dotted var(--main-color);
    border-radius: 12px;
    padding: 2rem;
    gap: 2rem;
}

.private-profile--info {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.private-profile--info img {
    max-width: 100px;
    max-height: 100px;
    display: block;
}

.private-profile--info div {
    text-align: left;
}

.private-profile--buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
}

.private-profile--socialnetwork {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1rem;
}


.private-profile--fav-info {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    background-color: var(--main-color);
    width: 100%;
    border-radius: 12px;
    color: var(--white-color);
    padding: 15px;
    text-decoration: none;
    align-items: center;
    text-transform: uppercase;
    transition: background-color 0.5s, color 0.25s;
    max-width: 500px;
    max-height: 230px;
    box-sizing: border-box;
    overflow: hidden;
}

.image-container {
    flex-shrink: 0;
    width: 200px;
    height: 150px;
    overflow: hidden;
    border-radius: 12px;
}

.private-profile--fav-info img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.private-profile--fav-info p {
    flex: 1;
    text-align: center;
}

.private-profile--fav-info:hover {
    background-color: var(--hover-color)
}

@media (max-width: 600px) {
    .private-profile {
        gap: 1rem;
    }

    .private-profile--info {
        flex-direction: column;
        align-items: center;
    }

    .private-profile--fav-info {
        flex-direction: column;
        max-width: 90%;
    }

    .private-profile--fav-info img {
        max-height: 150px;
    }
}