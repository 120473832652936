.spinner-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 100%;
}

.spinner-loader {
    display: flex;
    flex-direction: column;
    max-width: 150px;
    box-shadow: none !important;
    text-align: center;
    justify-content: space-between;
    gap: 5rem;
}

.spinner-loader p {
    font-weight: bolder;
}