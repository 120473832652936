.cities {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.cities ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.cities li {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 12px;
  background: var(--main-color);
  color: var(--white-color);
  padding: 1rem;
}

.cities li .cities-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.cities h3 {
  margin: 0;
}

.cities span {
  font-size: 1rem;
  opacity: .9;
}

.cities img {
  border-radius: 12px;
  width: 100%;
  max-width: 350px;
  height: auto;
  aspect-ratio: 16/9;
  display: block;
  object-fit: cover;
}


.cities--filters {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.cities--filters>div {
  display: flex;
  gap: 1rem;
  align-items: center;
  text-align: left;
}

.cities--filters label {
  font-size: 1rem;
}

.custom-select {
  min-width: 150px;
  position: relative;
}

.custom-select select {
  -webkit-appearance: none;
  /* Safari */
  -moz-appearance: none;
  /* Firefox */
  appearance: none;
  /* Standard */
  outline: none;
  background-color: var(--main-color);
  color: var(--white-color);
  width: 100%;
  font-size: 1rem;
  padding: 0.5em 5em 0.5em 1em;
  border: 1px solid #caced1;
  border-radius: 12px;
  cursor: pointer;

}

.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  position: absolute;
  content: "";
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid #fff;
  top: 40%;
}

.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid #fff;
  top: 55%;
}

.custom-select select option {
  background-color: var(--main-color);
  color: var(--white-color);
}

.custom-select select option:checked {
  background-color: var(--brand-color);
  color: var(--main-color);
}

.custom-select select:focus option:hover {
  background-color: var(--hover-color);
  color: var(--white-color);
}

@media (max-width: 768px) {
  .cities ul {
    grid-template-columns: 1fr;
  }
}