.fav-button {
    display: flex;
    justify-content: center;
    background: rgba(255, 172, 172, .3);
    border: 0;
    border-radius: 100px;
    cursor: pointer;
    width: 1px;
}

.fav-button:hover {
    background: rgba(255, 172, 172, .8);
}

.fav-button span {
    display: block;
}