.sidenav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.sidenav nav {
    width: 100%;
}

.sidenav a {
    display: flex;
    align-items: center;
    padding: 15px 15px;
    text-decoration: none;
    color: var(--brand-color);
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s ease, border-radius 0.3s ease;
}

.sidenav a:hover {
    background-color: var(--hover-color);
    border-radius: 25px;
}

.sidenav a i {
    margin-right: 15px;
    width: 24px;
    font-size: 24px;
}

.navbarside-link--active {
    background-color: var(--hover-color);
    border-radius: 25px;
}


@media (max-width: 900px) {
    .sidenav a {
        justify-content: center;
        padding: 10px 10px;
    }

    .sidenav a span {
        display: none;
    }

    .leftSide a i {
        margin: 0;
        font-size: 24px;
    }
}

@media (max-width: 480px) {
    .sidenav nav {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
    }

    .sidenav a {
        padding: 15px 15px;
    }
}