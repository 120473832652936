.form--auth {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0rem 2rem 0 2rem;
    margin: 0 auto;
    border-radius: 12px;
    max-width: 80%;
    width: 100%;
    text-align: center;
    align-items: center;
}

.form--auth__title {
    font-size: 2rem;
    margin: 0;
}

.form--auth__input-group {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
}

.form--auth__input-group>label {
    font-weight: bold;
    margin-bottom: 1rem;
}

.form--auth__input-group-input {
    padding: 10px;
    border: 1px solid var(--hover-color);
    border-radius: 12px;
}

.input-error {
    border: 2px solid var(--error-color);
}

.form--auth__link {
    color: var(--main-color);
}

.form--auth__link:hover {
    color: var(--hover-color);
}

.form--auth__errorMessage {
    background-color: var(--error-color);
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: 12px;
    padding: 1rem;
}

.form--auth__errorMessage>h3 {
    font-size: 1rem;
    margin: 0;
    margin-bottom: 1rem;
}

@media (min-width: 667px) {

    .form--auth {
        margin-top: 10px;
        gap: 2rem;
        padding: 2rem 2rem 0 2rem;
        max-width: 400px;
    }

    .form--auth__title {
        font-size: 2rem;
    }
}