.city {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 750px;
    margin: 0 auto;
}

.city section {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.main--button {
    align-items: end;
}

.city hr {
    width: 50%;
    border-color: var(--main-color);
    border-style: solid;
}

.image-container-city {
    position: relative;
    width: 100%;
}

.city-buttons {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
}

.city img {
    box-shadow: var(--box-shadow);
    border-radius: 12px;
    width: 100%;
    aspect-ratio: 16/9;
    display: block;
    object-fit: cover;
}

.city ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
}

.city li {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: var(--box-shadow);
    border-radius: 12px;
    padding: 1rem;
    background: var(--main-color);
    color: var(--white-color);
    text-align: center;
    justify-content: space-between;
}

.city ul img {
    border-radius: 12px;
    width: 100%;
    /* aspect-ratio: 9/16; */
    display: block;
    object-fit: cover;
    background: var(--white-color);
}

.city h1 {
    text-align: center;
    text-transform: uppercase;
}

.created-travel li {
    min-height: fit-content
}